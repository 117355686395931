export default {
  inputs: {
    rangeDate: {
      from: {
        default: 'de',
        display: 'del',
        time: 'de'
      },
      to: {
        default: 'a',
        display: 'al',
        time: 'a'
      }
    },
    alias: {
      label: 'Usuario',
      rules: {
        required: 'Debes ingresar un nombre de usuario.',
        required2: 'Debes seleccionar un usuario.'
      }
    },
    name: {
      label: 'Nombre',
      rules: {
        required: 'Debes indicar el nombre.'
      }
    },
    surname: {
      label: 'Apellido/s'
    },
    socials: {
      label: 'Redes sociales'
    },
    link: {
      label: 'Enlace',
      rules: {
        required: 'Debes añadir el enlace.',
        isLink: 'El enlace no es válido.'
      }
    },
    followers: {
      label: 'Seguidores'
    },
    videoUrl: {
      label: 'Video'
    },
    email: {
      label: 'Email',
      rules: {
        required: 'Debes añadir tu correo eletrónico.',
        isEmail: 'El correo electrónico no es válido.'
      }
    },
    role: {
      label: 'Rol',
      rules: {
        required: 'Debes escoger un rol para la cuenta.'
      }
    },
    password: {
      label: 'Contraseña',
      rules: {
        required: 'Debes ingresar la contraseña.',
        required2: 'Escribe una nueva contraseña.',
        lower: 'La contraseña debe contener al menos {n} carácter en minúscula.',
        upper: 'La contraseña debe contener al menos {n} carácter en mayúscula.',
        number: 'La contraseña debe contener al menos {n} número.',
        symbol: 'La contraseña debe contener al menos {n} carácter especial.',
        min: 'La contraseña debe contener un mínimo de {n} caracteres.'
      }
    },
    repeatPassword: {
      label: 'Repetir contraseña',
      rules: {
        required: 'Repite la contraseña.',
        sameAs: 'La contraseña no coincide.'
      }
    },
    code: {
      label: 'Código',
      rules: {
        required: 'Debes añadir el código.'
      }
    },
    parent: {
      label: 'Categoría padre'
    },
    picture: {
      label: 'Imagen',
      placeholder: 'Selecciona una imágen',
      multiplaceholder: 'Subir imagenes',
      uploadError: 'Ha ocurrido un error al subir el archivo.',
      clear: 'Eliminar imágen',
      rules: {
        uploading: 'Subiendo imágenes...'
      }
    },
    pics: {
      label: 'Imágenes'
    },
    status: {
      label: 'Estado',
      true: 'Activo',
      false: 'Inactivo'
    },
    bookingStatus: {
      '-1': 'Cancelada',
      0: 'No publicada',
      1: 'Pendiente',
      2: 'Aceptada',
      3: 'Rechazada'
    },
    idCategory: {
      label: 'Categoría',
      rules: {
        required: 'Debes indicar la categoría a la que pertenece.'
      }
    },
    idCategoryFilter: {
      label: 'Filtro',
      rules: {
        required: 'Debes indicar el filtro al que pertenece.'
      }
    },
    filters: {
      label: 'Filtros'
    },
    filterItems: {
      label: 'Subcategorías'
    },
    phone: {
      label: 'Teléfono'
    },
    address: {
      label: 'Dirección',
      rules: {
        required: 'Debes indicar la dirección.'
      }
    },
    location: {
      label: 'Localización'
    },
    area: {
      label: 'Area',
      rules: {
        required: 'Debes indicar el area al que pertenece.'
      }
    },
    description: {
      label: 'Descripción'
    },
    customer: {
      label: 'Cliente',
      rules: {
        required: 'Debes indicar el cliente al que pertenece.'
      }
    },
    people: {
      label: 'Max. personas',
      label2: 'Personas',
      placeholder: 'Indefinido',
      rules: {
        required: 'Debes especificar el número máximo de personas.',
        required2: 'Debes especificar el número de personas.',
        max: 'El número máximo de personas es {n}'
      }
    },
    conditions: {
      label: 'Condiciones',
      rules: {
        required: 'Debes especificar las condiciones de la oferta.'
      }
    },
    tag: {
      label: 'Etiquetas'
    },
    app: {
      label: 'Visible en la app'
    },
    startDate: {
      label: 'Fecha de inicio',
      rules: {
        required: 'Debes especificar la fecha de inicio.',
        beforeDate: 'La fecha no puede ser superior a la fecha de fin.'
      }
    },
    endDate: {
      label: 'Fecha de fin',
      rules: {
        required: 'Debes especificar la fecha de fin.',
        afterDate: 'La fecha no puede ser inferior a la fecha de inicio.'
      }
    },
    startOffset: {
      label: 'Comienza',
      rules: {
        required: 'Debes especificar la hora de inicio.'
      }
    },
    endOffset: {
      label: 'Termina',
      rules: {
        required: 'Debes especificar la hora de fin.'
      }
    },
    weekdays: {
      label: 'Se repite cada',
      rules: {
        required: 'Debes seleccionar al menos un dia de la semana.'
      }
    },
    timetable: {
      label: 'Horario'
    },
    offer: {
      label: 'Oferta',
      rules: {
        required: 'Debes seleccionar una oferta.'
      }
    },
    observations: {
      label: 'Observaciones'
    },
    date: {
      label: 'Fecha',
      rules: {
        required: 'Debes especificar una fecha.'
      }
    },
    time: {
      label: 'Hora de la reserva',
      rules: {
        required: 'Debes especificar una hora.'
      }
    },
    priority: {
      label: 'Prioridad'
    },
    position: {
      label: 'Posición'
    }
  },
  alerts: {
    save: 'Los datos se han guardado correctamente.',
    error: 'Ha habido un error inesperado. Prueva de nuevo más tarde.',
    authError: 'Sus credenciales de acceso no son correctas. Por favor, pruebe de nuevo más adelante.',
    recoveryPass: 'Se ha enviado un correo eletrónico con el enlace para cambiar la contraseña.<br>Revisa la bandeja de tu correo eletrónico.',
    resetPass: 'La contraseña se ha restablicido correctamente.',
    removeTitle: '¿Confirma que desea eliminar los elementos seleccionados?',
    removeText: 'Esta acción no será reversible una vez efectuada <br/>¿Está seguro que desea continuar?',
    removeError: 'No se han podido borrar los elementos seleccionados.',
    userExist: 'Ya existe un usuario con el mismmo nombre de usuario o email.',
    uploadError: 'Ha habido un error al subir el archivo. Por favor, pruebe de nuevo más adelante.',
    invalidTagVideo: 'Su navegador no soporta la etiqueta de vídeo.',
    invalidVideo: 'Ha habido un error al cargar el video.',
    sesionFinished: 'La sesión ha caducado.'
  },
  btn: {
    save: 'Guardar',
    add: 'Añadir',
    new: 'Nuevo',
    edit: 'Editar',
    export: 'Exportar',
    remove: 'Eliminar',
    cancel: 'Cancelar',
    accept: 'Aceptar',
    send: 'Enviar',
    clean: 'Limpiar',
    apply: 'Aplicar',
    filters: 'Filtros',
    login: 'Iniciar sesión',
    logout: 'Cerrar sesión',
    forgotPass: 'He olvidado mi contraseña',
    select: 'Seleccionar',
    selectAll: 'Seleccionar todo',
    unselect: 'Deseleccionar',
    unselectAll: 'Deseleccionar todo',
    addSocial: 'Añadir red social',
    file: 'Examinar'
  },
  menu: {
    profile: 'Mi perfil',
    users: '@:users.title',
    appUsers: '@:appUsers.title',
    area: '@:area.title',
    category: '@:category.title',
    categoryFilter: '@:categoryFilter.title',
    categoryFilterItem: '@:categoryFilterItem.title',
    customer: '@:customer.title',
    offer: '@:offer.title',
    offerMatch: '@:offerMatch.title',
    tag: '@:tag.title'
  },
  login: {
    title: 'Log In'
  },
  recoveryPass: {
    title: 'Recuperar contraseña',
    info: 'Por favor, introduce tu dirección de correo electrónico. '
        + 'Recibirás un mensaje de correo electrónico con instrucciones sobre cómo restablecer '
        + 'tu contraseña.',
  },
  resetPass: {
    title: 'Resetear contraseña',
    info: 'Por favor, introduce tu nueva contraseña.',
  },
  users: {
    title: 'Usuarios',
    notFound: 'El usuario no existe',
    removeTitle: '¿Confirma que desea eliminar las cuentas seleccionadas?',
    removeOneTitle: '¿Confirma que desea eliminar la cuenta?',
    changeEmailTitle: '¿Confirma que desea cambiar el correo electrónico?',
    changeEmailBody: 'Esto cambiará las credenciales de acceso del usuario.',
    roles: {
      '-1': 'Todos',
      '99': 'Administrador',
      '0': 'Usuario',
      '1': 'Gestor',
      '2': 'Editor'
    },
    status: {
      '-2': 'Pendiente',
      '-1': 'Baneado',
      '0': 'Deshabilitado',
      '1': 'Activo',
      'alert0': 'Cuenta deshabilitada',
      'alert-2': 'Validación pendiente',
      'alert-1': 'Cuenta baneada'
    },
    btn: {
      new: 'Nuevo usuario',
      edit: 'Editar usuario',
      remove: 'Eliminar cuenta',
      save: '@:btn.save'
    }
  },
  appUsers: {
    title: 'Usuarios (App)'
  },
  area: {
    title: 'Areas',
    notFound: 'El area no existe.',
    btn: {
      new: 'Nueva area',
      edit: 'Editar area'
    }
  },
  tag: {
    title: 'Etiquetas',
    notFound: 'La etiqueta no existe.',
    btn: {
      new: 'Nueva etiqueta',
      edit: 'Editar etiqueta'
    }
  },
  category: {
    title: 'Categorias',
    emptyFilters: 'Todavía no se han asignado filtros.',
    notFound: 'La categoría no existe.',
    btn: {
      new: 'Nueva categoría',
      edit: 'Editar categoría'
    },
    status: {
      '-1': 'Deshabilitada',
      '1': 'Activa',
      '2': 'Bloqueada'
    }
  },
  categoryFilter: {
    title: 'Filtros',
    emptyFilterItems: 'Todavía no se han asignado subcategorías.',
    notFound: 'El filtro no existe.',
    alerts: {
      removeTitle: '¿Confirma que desea eliminar los filtros seleccionados?',
      removeText: 'Se eliminarán todas las subcategorías asignadas al filtro<br/>¿Está seguro que desea continuar?',
    },
    btn: {
      new: 'Nuevo tipo de categoría',
      edit: 'Editar tipo de categoría'
    }
  },
  categoryFilterItem: {
    title: 'Subcategorías',
    notFound: 'La subcategoría no existe.',
    btn: {
      new: 'Nueva subcategoría',
      edit: 'Editar subcategoría'
    }
  },
  customer: {
    title: 'Clientes',
    notFound: 'El cliente no existe',
    btn: {
      new: 'Nuevo Cliente',
      edit: 'Editar Cliente'
    }
  },
  offer: {
    title: 'Ofertas',
    notFound: 'La oferta no existe',
    changeStatusTitle: '¿Confirma que desea deshabilitar la oferta?',
    changeStatusText: 'Todas las reservas asociadas a la oferta será rechazadas.',
    btn: {
      new: 'Nueva oferta',
      edit: 'Editar oferta'
    },
    status: {
      '0': 'Deshabilitada',
      '1': 'Activa',
      '2': 'Bloqueada'
    }
  },
  offerMatch: {
    title: 'Reservas',
    singular: 'Reserva',
    notFound: 'La reserva no existe',
    btn: {
      new: 'Nueva reserva',
      edit: 'Editar reserva'
    }
  }
};
